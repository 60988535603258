import React from "react"
import get from 'lodash/get'
import replace from 'lodash/replace'
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { SocialIcon } from '../components/ds/atoms/icons'

const PartnerProfilePageTemplate = ({ pageContext, data, location }) => {
  const footerData = get(data, 'sanityFooter')
  const partner = get(data, 'sanityPartner')
  const locale = get(pageContext, 'locale')

  const name = get(partner, 'name')
  const website = get(partner, 'website')

  const websiteClean = replace(replace(website, 'https://', ''), '/', '')

  const socialLinks = get(partner, 'socialLinks')
  const bio = get(partner, ['bio', locale])
  const description = locale === 'sv' ? get(partner, 'description._rawSv') : get(partner, 'description._rawEn')

  const serializers = {}

  return (
    <>
      <SEO
        title={name}
        description={bio}
        lang={get(pageContext, 'locale')}
        pathname={get(location, 'pathname')}
      />
      <Layout locale={get(pageContext, 'locale')} footer footerContent={footerData}>
        <div className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="hidden lg:block bg-cool-grey-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <p className="text-base leading-6 text-vivid-light-blue-600 font-semibold tracking-wide">
                <a href={website} target="_blank" rel="noopener noreferrer" className="flex space-x-2 items-center">
                  <span>{websiteClean}</span><SocialIcon type="external" />
                </a>
              </p>
              <h1 className="sr-only mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-cool-grey-900 sm:text-4xl sm:leading-10">{name}</h1>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
                <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
                  <defs>
                    <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="100" height="18" patternUnits="userSpaceOnUse">
                      <path className="text-cool-grey-100" fill="currentColor" d="M61.82 18c3.47-1.45 6.86-3.78 11.3-7.34C78 6.76 80.34 5.1 83.87 3.42 88.56 1.16 93.75 0 100 0v6.16C98.76 6.05 97.43 6 96 6c-9.59 0-14.23 2.23-23.13 9.34-1.28 1.03-2.39 1.9-3.4 2.66h-7.65zm-23.64 0H22.52c-1-.76-2.1-1.63-3.4-2.66C11.57 9.3 7.08 6.78 0 6.16V0c6.25 0 11.44 1.16 16.14 3.42 3.53 1.7 5.87 3.35 10.73 7.24 4.45 3.56 7.84 5.9 11.31 7.34zM61.82 0h7.66a39.57 39.57 0 0 1-7.34 4.58C57.44 6.84 52.25 8 46 8S34.56 6.84 29.86 4.58A39.57 39.57 0 0 1 22.52 0h15.66C41.65 1.44 45.21 2 50 2c4.8 0 8.35-.56 11.82-2z" />
                    </pattern>
                  </defs>
                  <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                </svg>
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="relative pb-5 lg:pb-0">
                      <GatsbyImage className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto" image={get(partner, 'brand.asset.localFile.childImageSharp.gatsbyImageData')} alt={name} />
                    </div>
                  </figure>
                </div>
              </div>
              <div>
                <div className="max-w-prose mx-auto lg:max-w-none py-10">
                  <GatsbyImage className="w-4/5 md:w-2/3 lg:w-1/2" image={get(partner, 'logoAlternative.asset.localFile.childImageSharp.gatsbyImageData')} alt={name} />
                </div>
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg leading-7 text-cool-grey-500 mb-5">{bio}</p>
                </div>
                <div className="prose text-cool-grey-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 mb-5">
                  <BlockContent blocks={description} serializers={serializers} />
                </div>
                <ul className="flex space-x-5 justify-center lg:justify-start">
                  {socialLinks.map(link => {
                    return (
                      <li key={link._key}>
                        <a href={link.profileUrl} target="_blank" rel="noopener noreferrer" className="text-cool-grey-400 hover:text-cool-grey-500 transition ease-in-out duration-150">
                          <span className="sr-only">{link.type}</span>
                          <SocialIcon type={link.type} />
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PartnerProfilePageTemplate

export const query = graphql`
  query GetPartner($id: String, $locale: String) {
    sanityPartner(_id: {eq: $id}) {
      _id
      name
      website
      bio {
        en
        sv
      }
      description {
        _rawEn
        _rawSv
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      brand {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      logoAlternative {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
